import AlgoliaInstantSearch from '../AlgoliaInstantSearch'
import CardListing from '../CardListing'
import DateRangeInput from '../DateRangeInput'
import TemplateFullWidth from '../TemplateFullWidth'
import FiltersModal from './FiltersModal'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Helmet } from 'react-helmet'
import {
  Index,
  connectInfiniteHits,
  Configure,
  RefinementList,
  ClearRefinements,
} from 'react-instantsearch-dom'
import { useDialogState } from 'reakit/Dialog'

// Update the path as necessary

export default function PageSearch(props) {
  const { site } = props.data
  const searchParams = new URLSearchParams(props.location.search)
  const filtersModal = useDialogState({ baseId: 'filtersModal' })

  return (
    <TemplateFullWidth>
      <Configure
        getRankingInfo='true'
        aroundLatLng={
          (searchParams.get('l') && searchParams.get('l')) || undefined
        }
        facetFilters={
          searchParams.get('c') && [`categories:${searchParams.get('c')}`]
        }
        removeWordsIfNoResults='allOptional'
        hitsPerPage={10}
      />

      <Index indexName='Listings'>
        <Helmet>
          <title>Search {site.siteMetadata.title}</title>
        </Helmet>

        <div className={style.pageWrapper}>
          <div className={style.filtersDesktop}>
            <form>
              <div className={style.inputSection}>
                <div className={style.searchSection}>
                  <h1 className={style.search}>Search</h1>
                  <div className={style.searchInput}>
                    <label className={style.label} htmlFor='location'>
                      Location
                    </label>
                    <div className={style.textInput}>
                      <AlgoliaInstantSearch
                        name='location'
                        placeholder='Search by city or state'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.inputSection}>
                <div className={style.searchSection}>
                  <div className={style.inputRow}>
                    <div>
                      <label className={style.label} htmlFor='location'>
                        Drop-off and pick-up dates
                      </label>
                      <DateRangeInput attribute='unavailability' />
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.inputSection}>
                <h4 className={style.filters}>Filters</h4>
                <div className={style.heading}>
                  <label className={style.label}>Accommodation</label>
                  <ClearRefinements
                    translations={{ reset: 'Clear filters' }}
                    className={style.clearFilters}
                  />
                </div>
                <RefinementList
                  className={style.inputGroup}
                  attribute='profileData.accomodationType'
                />
              </div>
              <div className={style.inputSection}>
                <label className={style.label}>Dog(s)</label>
                <div className={style.radios}>
                  <div className={style.inputGroup}>
                    <input type='radio' id='one' name='dogs' value='one' />
                    <label htmlFor='one'>1</label>
                  </div>
                  <div className={style.inputGroup}>
                    <input type='radio' id='two' name='dogs' value='two' />
                    <label htmlFor='two'>2</label>
                  </div>
                  <div className={style.inputGroup}>
                    <input type='radio' id='three' name='dogs' value='three' />
                    <label htmlFor='three'>3</label>
                  </div>
                  <div className={style.inputGroup}>
                    <input type='radio' id='four' name='dogs' value='four' />
                    <label htmlFor='four'>4</label>
                  </div>
                </div>
              </div>
              <div className={style.inputSection}>
                <label className={style.label}>Dog size</label>
                <RefinementList
                  className={style.inputGroup}
                  attribute='profileData.dogSize'
                />
              </div>
              <div className={style.inputSection}>
                <label className={style.label}>Price range</label>
                <RefinementList
                  className={style.inputGroup}
                  attribute='profileData.priceRange'
                />
              </div>
              <div className={style.inputSection}>
                <label className={style.label}>Additional criteria</label>
                <RefinementList
                  className={style.inputGroup}
                  attribute='profileData.additionalCriteria'
                />
              </div>
            </form>
          </div>

          <div className={style.searchResults}>
            <FiltersModal {...filtersModal} />

            <ListingIndex />
          </div>
        </div>
      </Index>
    </TemplateFullWidth>
  )
}

const Listings = ({ hits, hasMore, refineNext }) => {
  if (typeof window === 'undefined') {
    return null // Return null during server-side rendering
  }

  return (
    <React.Suspense fallback={null}>
      {hits.length ? (
        <>
          <div className={style.listings}>
            {hits.map((hit) => (
              <CardListing
                alt=''
                key={hit.id}
                link={{
                  pathname: `/${hit.id}/`,
                }}
                image={hit.image}
                date={hit.date}
                listing={hit}
                services={hit.services}
                city={hit.city}
                title={hit.postTitle}
                accomodationType={hit.profileData?.accomodationType}
                additionalCriteria={hit.profileData?.additionalCriteria}
                dogCriteria={hit.profileData?.dogCriteria}
              />
            ))}
            {hasMore && (
              <button className={style.button} onClick={() => refineNext()}>
                Load More Results
              </button>
            )}
          </div>
        </>
      ) : (
        <div className={style.noResults}>
          <div className={style.doggo}>
            <StaticImage
              src={'./image.png'}
              alt='Illustrated image of a girl and shepherd dog looking for something in the distance'
              className={style.image}
              placeholder='none'
            />
          </div>
          <div className={`${style.container} ${style.results}`}>
            <h2>No results</h2>
            <p>
              We can't find results for this search. Try removing some filters.
            </p>
          </div>
          <div className={`${style.container} ${style.sitters}`}>
            <h3>For dog sitters</h3>
            <p>
              Want to see your business in these search results? Request an
              account by going to the{' '}
              <Link to={'/become-a-sitter'}>"Become a sitter"</Link> page and
              following the steps outlined there.
            </p>
          </div>
        </div>
      )}
    </React.Suspense>
  )
}

const ListingIndex = connectInfiniteHits(Listings)
